import React from 'react'
import Layout from '../components/Layout'
import { Subprocessors } from '../components/Subprocessors'
import Block from '../components/primitives/Block'

const SubprocessorsPage = () => (
  <Layout justifyCenter title="Subprocessors">
    <Block size="medium">
      <Subprocessors />
    </Block>
  </Layout>
)

export default SubprocessorsPage
